import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-notifications-component/dist/theme.css'

import Login from "../Components/Login/Login";
import Addonlist from "../Addonlist/Addon";
import Appointment from "../Appointments/Appointment";
import Dashboard from "../Dashboard/Dashboard";
import Schedule from "../Schedule/Schedule";
import Services from "../Services/Services";
import Setting from "../Setting/Setting";
import Technician from "../Technician/Technician";
import AppointmentMain from "../Appointments/AppointmentMain";
import Timesheet from "../Timesheet/Timesheet";
import Offtimerequest from "../Offtimerequest/offtimerequest"
import Keapsyncform from "../Keapsyncform/keapsyncform"
import NotFound from "../Components/Notfound.js";

import Addon from '../Addonlist/Addon';



import Crm from '../crm/Crm';
import Serviceprice from '../Services/Servicesprice';
import ForgotPassword from '../Components/Login/Forgotpassword';
import forgetpassword2 from '../Components/Login/Forgetpassword2.js';
import UserProfile from '../crm/UserProfile';
import TechnicianMain from "../Technician/TechnicianMain";
import SettingMain from "../Setting/SettingMain"
import TechnicianAvailability from "../Technician-availability/TechnicianAvailability";
import MapView from "../Appointments/MapView";
import Gettechapps from "../Appointments/Gettechapps.js";
import Getmissingappointment from "../Appointments/Getmissingappointment.js";
import Getmarge from "../crm/Getmarge.js";

import Getstaxdata from "../Stax/Getstaxdata.js";

import Allnotification from "../blogs/allnotification";
import Review from "../review/review";


import Home from '../Booking/Home';
import About from '../Booking/Aboutus';
import Housecleaning from '../Booking/HouseCleaning';
import WindowCleaning from '../Booking/WindowCleaning';
import CleaningTip from '../Booking/CleaningTip';
import Reviews from '../Booking/Review';
import InitalCleaning from '../Booking/InitalCleaning';
import Scheduled from '../Booking/Scheduled';
import MoveInOut from '../Booking/MoveIn';
import LaMase from '../Booking/LaMase';
import SanDiego from '../Booking/SanDiego';
import CheckList from '../Booking/CheckList';
import Booking from '../Booking/Booking';
import Step1 from '../Booking/camponent/BookingForm/Step01';
import Step2 from '../Booking/camponent/BookingForm/Step02';
import Step3 from '../Booking/camponent/BookingForm/Step03';
import Ourproduct from '../Booking/OurProduct';
import Contactus from '../Booking/Contactus';
import Dashboard2 from '../Booking/Innerpages/Dashboard/Dashboard';
import GetReport from '../Components/DataTable Getreport/Informasi'
import BookingMain from '../Booking/camponent/BookingForm/BookingMain';
import { LoginProvider } from '../Booking/LoginContext';
import Newcontact from "../NewContact/newcontact.js";
import Covid from "../Booking/Covid-19.jsx";
import Application from "../Booking/Job-Application.jsx";
import WindowCleaningCost from "../Booking/Window-Cleaning-cost.jsx";
import PrivacyPolicy from "../Booking/PrivacyPolicy.jsx";
import WindowCleaningPrice from "../Booking/Window-Cleaning-price.jsx";
import BlogList from "../Booking/BlogList.jsx";
import BlogSingle from "../Booking/BlogSingle.jsx";
import CreateBlog from "../blogs/createblog.jsx";
import BlogCategory from "../blogs/blog_category.jsx";
import BlogTag from "../blogs/blog_tag.jsx";
import Googleleads from "../googleleads/googleleads.jsx";
import Getreviews from "../getreviews/review.jsx";
import Singleview from "../getreviews/singleview.jsx";
import Confirmbooking from "../Confirmation";
import Pricing from "../pricing/pricing.js";



const MainRouter = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/adminlogin" component={Login} />
                <PrivateRoute exact path="/addonlist" component={Addonlist} />
                <PrivateRoute exact path="/appointments" component={Appointment} />
                <PrivateRoute exact path="/schedule" component={Schedule} />
                <PrivateRoute exact path="/services" component={Services} />
                <PrivateRoute exact path="/technician" component={TechnicianMain} />
                <PrivateRoute exact path="/setting" component={SettingMain} />
                <PrivateRoute exact path="/timesheet" component={Timesheet} />
                <PrivateRoute exact path='/dashboard' component={Dashboard} />
                <PrivateRoute exact path='/appointment' component={AppointmentMain} />
                <PrivateRoute exact path='/crm' component={Crm} />
                <PrivateRoute exact path='/serviceprice' component={Serviceprice} />
                <PrivateRoute exact path='/serviceprice/:id' component={Serviceprice} />
                <PrivateRoute exact path='/addon' component={Addon} />
                <PrivateRoute exact path='/resetpassword' component={ForgotPassword} />
                <PrivateRoute exact path='/userprofile' component={UserProfile} />
                <PrivateRoute exact path='/technicianavailability' component={TechnicianAvailability} />
                <PrivateRoute exact path='/mapview' component={MapView} />
                <PrivateRoute exact path="/getreport" component={GetReport} />
                <PrivateRoute exact path="/offtimerequest" component={Offtimerequest} />
                <PrivateRoute exact path="/keapsyncform" component={Keapsyncform} />
                <PrivateRoute exact path="/newcontact" component={Newcontact} />
                <PrivateRoute exact path="/allnotification" component={Allnotification} />
                <PrivateRoute exact path="/review" component={Review} />
                <PrivateRoute exact path="/create-blog" component={CreateBlog} />
                <PrivateRoute exact path="/blog-category" component={BlogCategory} />
                <PrivateRoute exact path="/blog-tag" component={BlogTag} />
                <PrivateRoute exact path="/googleleads" component={Googleleads} />
                <PrivateRoute exact path="/getreviews" component={Getreviews} />
                <PrivateRoute exact path="/singleview" component={Singleview} />
                <PrivateRoute exact path="/gettechapps" component={Gettechapps} />
                <PrivateRoute exact path="/getmissingappointment" component={Getmissingappointment} />
                <PrivateRoute exact path="/getmarge" component={Getmarge} />
                <PrivateRoute exact path="/getstaxdata" component={Getstaxdata} />
                <PrivateRoute exact path="/pricing" component={Pricing} />


                <Route exact path='/confirmbooking' component={Confirmbooking} />
                <Route exact path='/forgetpassword' component={forgetpassword2} />





                <LoginProvider>
                    <Route exact path='/' component={Booking} />
                    <Route exact path='/home' component={Home} />
                    <Route path='/about-us' component={About} />
                    <Route path='/house-cleaning-services' component={Housecleaning} />
                    <Route path='/window-cleaning/' component={WindowCleaning} />
                    <Route path='/cleaningTip' component={CleaningTip} />
                    <Route path='/reviews/' component={Reviews} />
                    <Route path='/project/initial-house-cleaning/' component={InitalCleaning} />
                    <Route path='/our-cleaning-products/' component={Ourproduct} />
                    <Route path='/project/maid-service/' component={Scheduled} />
                    <Route path='/project/move-in-out-cleaning-service/' component={MoveInOut} />
                    <Route path='/project/la-mesa-house-cleaning/' component={LaMase} />
                    <Route path='/project/san-diego-house-cleaning/' component={SanDiego} />
                    <Route path='/project/cleaning-services-checklist/' component={CheckList} />
                    <Route path='/covid-19-update/' component={Covid} />
                    <Route path='/house-cleaning-technician-job-application/' component={Application} />
                    <Route path='/how-much-does-window-cleaning-cost/' component={WindowCleaningCost} />
                    <Route path='/green-frog-privacy-policy/' component={PrivacyPolicy} />
                    <Route path='/san-diego-window-cleaning-prices/' component={WindowCleaningPrice} />
                    <Route path='/blog/' component={BlogList} />
                    <Route path='/blog-single/' component={BlogSingle} />
                    <Route path='/booking' component={Booking} />
                    <Route path='/step1' component={Step1} />
                    <Route path='/step2' component={Step2} />
                    <Route path='/step3' component={Step3} />
                    <Route path='/contact' component={Contactus} />
                    <Route path='/userdashboard' component={Dashboard2} />
                </LoginProvider>


                <Route component={NotFound} />
            </Switch>
        </Router>
    );
}


export default MainRouter;