import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import moment from "moment-timezone";
import "./Offtimerequest.css";
import BootstrapTable from 'react-bootstrap-table-next';
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { FormControl } from "react-bootstrap";
import pagination from '../Components/TimesheetDataTable/pagination';
import Form from 'react-bootstrap/Form';
import '../Components/TimesheetDataTable/table.css';
import { getActiveStaff } from "../Api/timesheetApi";
import { TechnicianOffTimerequest } from '../Api/technician';
import { approveTechnicianOffTimerquest } from "../Api/technician";
import { rejectTechnicianOffTimerquest, updateofftimerequest } from "../Api/technician";
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import Button from 'react-bootstrap/Button';
import { Roller } from "react-awesome-spinners";
import { FaRegEdit } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { DateRangePicker } from 'react-date-range';
// import moment from 'moment';

function Offtimerequest() {


    const [technicianofftimerequest, setTechnicianofftimerequest] = useState([]);

    const [ActiveStaff, setActiveStaff] = useState([]);

    const [loading, setLoading] = useState(false)

    const [StaffId, setStaffId] = useState('');
    const [techname, setTechName] = useState('');
    const [statustype, setStatustype] = useState('');

    const [startTimes, setStartTimes] = useState('');
    const [endTimes, setEndTimes] = useState('');
    const [requesttype, setRequesttype] = useState('');
    const [requeststatus, setRequestStatus] = useState('');
    const [id, setId] = useState('');


    const [show11, setShow11] = useState(false);

    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);

    const [show9, setShow9] = useState(false);

    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);

    const [loadingsppiner, setLoadingSpinner] = useState(false);

    const today = new Date();
    const [sdate, setSdate] = useState("2023-01-01");
    // const sevenDaysLater = new Date();
    // const [edate, setEdate] = useState(sevenDaysLater.setDate(sevenDaysLater.getDate() + 7));
    const [edate, setEdate] = useState("2050-12-31");

    const [showDateDisplay, setShowDateDisplay] = useState(false);

    const [dates, setDates] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        const startDate = new Date(sdate);
        const endDate = new Date(edate);

        setDates([
            {
                startDate: startDate,
                endDate: endDate,
                key: 'selection'
            }
        ]);
    }, [sdate, edate]);

    const getActiveStaffList = () => {
        setLoading(true);
        getActiveStaff().then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setActiveStaff(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const getTechnicianOffTimerequest = () => {
        setLoading(true);
        const startTimeeee = moment(sdate).format("YYYY-MM-DD 00:00:00");
        const endTimeeee = moment(edate).format("YYYY-MM-DD 23:59:59");
        TechnicianOffTimerequest(StaffId, startTimeeee, endTimeeee, statustype, techname).then((res) => {
            if (res.code === 200) {
                setLoading(false);
                const OffTime = res.data;
                setTechnicianofftimerequest(OffTime.data);
            }
            else {
                console.log("getTechnicianOffTime");
                alert("No data found");
            }
        }).catch((err) => {
            console.log("err", err);
        }).finally(() => {
            handleClose9();
        })
    };


    const data = technicianofftimerequest && technicianofftimerequest.map((item) => {
        const start_date = item.start_date_time.split(" ");
        const end_date = item.end_date_time.split(" ");

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const start_date_split = start_date[0].split("-");
        const end_date_split = end_date[0].split("-");
        start_date[0] = monthNames[parseInt(start_date_split[1], 10) - 1] + "-" + start_date_split[2] + "-" + start_date_split[0];
        end_date[0] = monthNames[parseInt(end_date_split[1], 10) - 1] + "-" + end_date_split[2] + "-" + end_date_split[0];

        const formatTime = (timeStr) => {
            let [hour, minute] = timeStr.split(":").map(Number);
            let period = "AM";

            if (hour === 0) {
                hour = 12;
                period = "AM";
            } else if (hour === 12) {
                period = "PM";
            } else if (hour > 12) {
                hour -= 12;
                period = "PM";
            }

            return `${hour}:${minute.toString().padStart(2, "0")} ${period}`;
        };

        start_date[1] = formatTime(start_date[1]);
        end_date[1] = formatTime(end_date[1]);

        return {
            id: item.id,
            startdate: start_date[0],
            starttime: start_date[1],
            enddate: end_date[0],
            endtime: end_date[1],
            off_time_note: item.notes,
            status: item.status,
            fullname: item.fullname,
            staff_id: item.staff_id,
            start_date_time: item.start_date_time,
            end_date_time: item.end_date_time
        };
    });



    useEffect(() => {
        setLoading(false);
        getActiveStaffList();
        getTechnicianOffTimerequest()
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const statusFormatter = (cell, row) => {
        return (
            <div>
                {row.status == "0" ? "Pending" : row.status == "1" ? "Approved" : "Rejected"}
            </div>
        );
    }

    const optionFormatter = (cell, row) => {
        return (
            <div>
                {/* <div className="button-group"> */}
                {/* <Button
                        className="Edit-Active-bttn me-2"
                        onClick={() => {
                            handleEdit(row);
                            setStartTimes(row.start_date_time);
                            setEndTimes(row.end_date_time);
                            setRequesttype(row.notes);
                            setId(row.id);
                            handleShow11();
                        }}
                    >
                        <FaRegEdit />
                    </Button> */}
                {/* </div> */}
                <div className="button-group">
                    <Button
                        className="Edit-Active-bttn me-2"
                        onClick={() => {
                            setStartTimes(row.start_date_time);
                            setEndTimes(row.end_date_time);
                            setRequesttype(row.off_time_note);
                            setRequestStatus(row.status);
                            setId(row.id);
                            handleShow11();
                        }}
                    >
                        <FaRegEdit />
                    </Button>
                    {/* <Button className="Approve-bttn me-2" style={{ backgroundColor: '#75B317', color: 'white' }} onClick={() => handleApprove(row)}>
                            <RiCheckLine />
                        </Button>
                        <Button className="Reject-bttn" style={{ backgroundColor: 'red', color: 'white' }} onClick={() => handleReject(row)}>
                            <RiCloseLine />
                        </Button> */}
                    {row.status === "0" ? (
                        <>
                            <Button className="Approve-bttn me-2" style={{ backgroundColor: '#75B317', color: 'white' }} onClick={() => handleApprove(row)}>
                                <RiCheckLine />
                            </Button>
                            <Button className="Reject-bttn" style={{ backgroundColor: 'red', color: 'white' }} onClick={() => handleReject(row)}>
                                <RiCloseLine />
                            </Button>
                        </>
                    ) : ""}
                </div>
            </div>

        );
    }


    const columns = [{
        dataField: 'id',
        text: '#',
        sort: true
    },
    {
        dataField: 'fullname',
        text: 'TECHNICIAN NAME',
        sort: true
    },
    {
        dataField: 'startdate',
        text: 'START DATE',
        sort: true,
        sortFunc: (a, b, order) => {
            if (order === 'asc') {
                return new Date(a) - new Date(b);
            } else {
                return new Date(b) - new Date(a);
            }
        }
    },
    {
        dataField: 'starttime',
        text: 'START TIME',
        sort: true
    },
    {
        dataField: 'enddate',
        text: 'END DATE',
        sort: true,
        sortFunc: (a, b, order) => {
            if (order === 'asc') {
                return new Date(a) - new Date(b);
            } else {
                return new Date(b) - new Date(a);
            }
        },
    },
    {
        dataField: 'endtime',
        text: 'END TIME',
        sort: true
    },
    {
        dataField: 'off_time_note',
        text: 'OFF TIME REQUEST NOTES',
        sort: true
    },
    {
        dataField: 'status',
        text: 'STATUS',
        sort: true,
        formatter: statusFormatter

    },
    {
        text: 'ACTION',
        isDummy: true,
        formatter: optionFormatter,
    }];

    const handleApprove = (data) => {
        console.log("data", data);
        if (window.confirm("Are you sure you want to approve this record?")) {
            const formData = new FormData();
            formData.append("off_time_id", data.id);
            formData.append("technician_id", data.staff_id);
            approveTechnicianOffTimerquest(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status == "1") {
                        alert(res.data.message);
                        // const newTechnicianOfftime = technicianofftimerequest.filter((item) => item.id !== data.id);
                        // setTechnicianofftimerequest(newTechnicianOfftime);
                        const newTechnicianOfftime = technicianofftimerequest.map((item) => {
                            if (item.id == data.id) {
                                item.status = "1";
                            }
                            return item;
                        }
                        );
                        setTechnicianofftimerequest(newTechnicianOfftime);
                    }
                }
                else {
                    alert("Something went wrong");
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const handleReject = (data) => {
        if (window.confirm("Are you sure you want to reject this record?")) {
            const formData = new FormData();
            formData.append("off_time_id", data.id);
            formData.append("technician_id", data.staff_id);
            rejectTechnicianOffTimerquest(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status == "1") {
                        alert(res.data.message);
                        //update technicianofftimerequest state and dont remove the rejected record
                        const newTechnicianOfftime = technicianofftimerequest.map((item) => {
                            if (item.id == data.id) {
                                item.status = "Rejected";
                            }
                            return item;
                        }
                        );
                        setTechnicianofftimerequest(newTechnicianOfftime);

                    }
                }
                else {
                    alert("Something went wrong");
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }


    useEffect(() => {
        getTechnicianOffTimerequest()
    }, [StaffId, statustype, techname]);


    const handleEdit = (row) => {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("start_date_time", moment(startTimes).format('YYYY-MM-DD HH:mm'));
        formData.append("end_date_time", moment(endTimes).format('YYYY-MM-DD HH:mm'));
        formData.append("notes", requesttype);
        formData.append("status", requeststatus);

        updateofftimerequest(formData).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                if (ApiResponse.status === "success") {
                    alert("Timesheet Updated Successfully");
                    handleClose11();
                    getTechnicianOffTimerequest();
                } else {
                    alert("Something went wrong");
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });

    }


    const handleButtonClick = () => {
        getTechnicianOffTimerequest();
    }



    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>

                    <Modal show={show9} onHide={handleClose9} centered className="modal-center">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Filter data
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="date-picker">
                                        <Form.Group
                                            className="booking-custom-lable"
                                            controlId="formBasicEmail"
                                        >
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <DateRangePicker
                                                        onChange={(item) => {
                                                            setSdate(item.selection.startDate);
                                                            setEdate(item.selection.endDate);
                                                            setDates([item.selection]);
                                                        }}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        // months={2}
                                                        ranges={dates}
                                                        direction="horizontal"
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                        </div> */}
                                <div className="date-picker">
                                    <Form.Group
                                        className="booking-custom-lable"
                                        controlId="formBasicEmail"
                                    >
                                        {
                                            loadingsppiner ? (
                                                <Button className="resize_btn btn" disabled>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </Button>
                                            ) : (
                                                <div className="toggle_btn">
                                                    <button className="resize_btn btn" onClick={
                                                        () => {
                                                            setTechnicianofftimerequest([]);
                                                            handleButtonClick();
                                                        }
                                                    }>
                                                        <div className="btn-text">
                                                            Submit
                                                        </div>
                                                    </button>
                                                </div>
                                            )
                                        }
                                    </Form.Group>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>


                    {/* Timesheet modal start here */}
                    <Modal show={show11} onHide={handleClose11} className="Sidebar-Modal-end">
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Time Sheet</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='custum-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Start Time</Form.Label>
                                        <Form.Control type="datetime-local" value={startTimes} onChange={
                                            (e) => {
                                                setStartTimes(e.target.value);
                                            }
                                        } placeholder="" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Stop Time</Form.Label>
                                        <Form.Control type="datetime-local" value={endTimes} onChange={
                                            (e) => {
                                                setEndTimes(e.target.value);
                                            }
                                        } placeholder="" />

                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Request type</Form.Label>
                                        <Form.Select aria-label="Default select example" value={requesttype} onChange={
                                            (e) => {
                                                console.log(e.target.value);
                                                setRequesttype(e.target.value);
                                            }
                                        } >
                                            <option value="">Select Off Time Request</option>
                                            <option value="Sick Time">Sick Time</option>
                                            <option value="Unrequested Absence">Unrequested Absence</option>
                                            <option value="Vacation Time">Vacation Time</option>
                                            <option value="Unpaid Time Off">Unpaid Time Off</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Request Status</Form.Label>
                                        <Form.Select aria-label="Default select example" value={requeststatus} onChange={
                                            (e) => {
                                                setRequestStatus(e.target.value);
                                            }
                                        } >
                                            <option value="">Select Off Time Request Status</option>
                                            <option value="0">Pending</option>
                                            <option value="1">Approved</option>
                                            <option value="2">Rejected</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="Active-bttn btn" onClick={handleEdit} > Update </button>
                            <button className="Cancel-bttn btn" onClick={handleClose11}> Cancel </button>
                        </Modal.Footer>
                    </Modal>
                    {/* modal End here */}

                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="row">
                                            <div className="select-box-list">

                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Technician</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={
                                                            (e) => {
                                                                setStaffId(e.target.value);
                                                            }
                                                        }>
                                                            <option value="0">Select Technician</option>
                                                            {ActiveStaff.map((item, index) => (
                                                                <option value={item.id} onChange={() => setStaffId(item.id)} selected={item.id === StaffId}>{item.fullname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>

                                                <div className="select-box-list-item" style={{ display: "none" }}>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Technician Name</Form.Label>
                                                        <Form.Control type="text" value={techname} onChange={(e) => { setTechName(e.target.value) }} placeholder="" />
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Request Status</Form.Label>
                                                        <Form.Select aria-label="Default select example" value={statustype} onChange={
                                                            (e) => {
                                                                setStatustype(e.target.value)
                                                            }
                                                        }>
                                                            <option >All Status</option>
                                                            <option value="0">Pending</option>
                                                            <option value="1">Approved</option>
                                                            <option value="2">Rejected</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label></Form.Label>
                                                        <div className="date-picker">
                                                            <button class="resize_btn btn" onClick={handleShow9}><div class="btn-text">Filter</div></button>
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="pd-26">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-header mb-4 d-flex justify-content-between">
                                                        <h5 className="card-title">Off Time Request Sheet</h5>
                                                        <div className="d-flex justify-content-end">
                                                            <div className="export-btn">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-card-body-inner">

                                                        {/* modal End here */}
                                                        <div className="animated fadeIn">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="card">
                                                                        <div className="card-header d-flex table-card-header">
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <BootstrapTable bootstrap4 keyField='id'
                                                                                data={data}
                                                                                columns={columns}
                                                                                pagination={pagination}
                                                                                noDataIndication="No data available"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Offtimerequest;