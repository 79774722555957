import React, { useState, useContext, useEffect } from "react";
import '../../assets/css/Booking.css';
import { BookingContext } from "../BookingForm/BookingContext";
import { LoginContext } from "../../LoginContext";
import { getsubservicebyservice, getuser, getuserdetails, applyPromo } from "../../Api/frontbooingApi";
import { getaddon } from "../../Api/frontbooingApi";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Totalpayment({ orderId }) {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword, signUprequest, setSignUprequest, technicianList, setTechnicianList, expectedprice, setExpectedprice, expectedduration, setExpectedduration, availableTimestatus, setAvailableTimestatus, resetChoosetechnician, zipcode, setZipcode, bookingdata, setBookingData, Newservicepercentage, setNewServicepercentage, oldtotalprice, setOldtotalPrice, promodata, setPromodata] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);

    const [userbilledhours, setUserbilledhours] = useState(0);
    const [userbilledamount, setUserbilledamount] = useState(0);
    const [userid, setUserid] = useState('');
    const [userloggedin, setUserloggedin] = useState(false);

    const [bedrooms, setBedrooms] = useState(1);
    const [bathrooms, setBathrooms] = useState(1);

    const [defaultsubservice, setdefaultsubservice] = useState([]);
    const [defaultaddons, setdefaultaddons] = useState([]);


    const checkAddonsQuantityChanged = (defaultAddons, chooseaddon) => {
        // Check if any default addon quantity has changed
        return defaultAddons.some((addon, index) =>
            parseInt(addon.quantity) !== parseInt(chooseaddon[index]?.quantity)
        );
    };

    const isQuantityChanged = checkAddonsQuantityChanged(defaultaddons, chooseaddon);


    useEffect(() => {
        if (LoggedInData != null) {
            setUserid(LoggedInData.id);
            setUserloggedin(true);
        } else {
            setUserloggedin(false);
        }
    }, [LoggedInData]);


    useEffect(() => {
        if (chooseservice === 0) {
            setdefaultsubservice([]);
            setdefaultaddons([]);
        }

        getsubservice();
        getaddonsservice();
    }, [chooseservice]);


    const getEndtime = (time) => {
        if (time == null || time == undefined || time == "") {
            return "";
        }
        else {

            const choosetimeDate = new Date(choosetime);

            // Check if the date is valid
            if (isNaN(choosetimeDate.getTime())) {
                console.error("Invalid time value");
                return "";
            }

            // Split the choosetime into hours and minutes
            const choosetimeHours = choosetimeDate.getHours();
            const choosetimeMinutes = choosetimeDate.getMinutes();


            const serviceArray = Array.isArray(choosesubservice) ? choosesubservice : [];
            const addonArray = Array.isArray(chooseaddon) ? chooseaddon : [];

            // Calculate the total quantity for chooseservice and chooseaddon
            const totalServiceQuantity = serviceArray.reduce((sum, service) => sum + (parseInt(service.quantity) || 0), 0);
            const totalAddonQuantity = addonArray.reduce((sum, addon) => sum + parseInt(addon.quantity || 0), 0);

            // Check if quantity conditions are met
            const isQuantityChangedSubserviceandaddons = totalServiceQuantity > (parseInt(bedrooms) + parseInt(bathrooms)) || totalAddonQuantity > 0;

            if (userbilledhours > 0 && userbilledhours !== undefined && userbilledhours != null && !isQuantityChangedSubserviceandaddons) {

                const userBilledHoursInMinutes = parseInt(userbilledhours);
                choosetimeDate.setMinutes(choosetimeDate.getMinutes() + userBilledHoursInMinutes);

                // Format the end time to a string in the 12-hour clock format
                const ampm = choosetimeDate.getHours() >= 12 ? 'PM' : 'AM';
                var endHours = choosetimeDate.getHours() % 12 || 12;
                var endMinutes = choosetimeDate.getMinutes();
                endHours = endHours < 10 ? '0' + endHours : endHours;
                const strTime = endHours + ':' + (endMinutes < 10 ? '0' + endMinutes : endMinutes) + ' ' + ampm;
                setEndchoosetime(strTime);
                return strTime;
            } else {
                // Calculate the expectedduration in hours
                const expecteddurationHours = expectedduration / 60;

                // Add the expectedduration in hours to the choosetime
                choosetimeDate.setHours(choosetimeHours + Math.floor(expecteddurationHours));
                choosetimeDate.setMinutes(choosetimeMinutes + (expecteddurationHours % 1) * 60);

                // Format the end time to a string in the 12-hour clock format
                const ampm = choosetimeDate.getHours() >= 12 ? 'PM' : 'AM';
                var endHours = choosetimeDate.getHours() % 12 || 12;
                var endMinutes = choosetimeDate.getMinutes();
                endHours = endHours < 10 ? '0' + endHours : endHours;
                const strTime = endHours + ':' + (endMinutes < 10 ? '0' + endMinutes : endMinutes) + ' ' + ampm;
                setEndchoosetime(strTime);
                return strTime;
            }


        }
    }


    useEffect(() => {
        getEndtime(choosetime);
    }, [totalDuration]);

    const getDate = (date) => {
        if (choosetime == null || choosetime == undefined || choosetime == "") {
            return "";
        } else {
            var d = new Date(date);
            var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            var dayName = days[d.getDay()];
            var monthName = d.toLocaleString('default', { month: 'short' });
            var date = d.getDate();
            var year = d.getFullYear();
            var fullDate = dayName + " " + date + " " + monthName + " " + year;
            return fullDate;
        }
    }

    const getEditDate = (date) => {
        var d = new Date(date);
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var dayName = days[d.getDay()];
        var monthName = d.toLocaleString('default', { month: 'short' });
        var date = d.getDate();
        var year = d.getFullYear();
        var fullDate = dayName + " " + date + " " + monthName + " " + year;
        return fullDate;
    }

    const getTime = (time) => {

        if (time == null || time == undefined || time == "") {
            return "";
        }
        else {
            var d = time && new Date(time);
            var hours = d.getHours();
            var minutes = d.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            console.log("minutes", minutes);
            minutes = minutes < 10 ? '0' + minutes : minutes;
            hours = hours < 10 ? '0' + hours : hours;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

    }

    const getsubservice = () => {
        // setLoading(true);
        const formData = new FormData();
        formData.append("service_id", chooseservice);
        formData.append("user_id", userid);
        getsubservicebyservice(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                // setSubService(apiResponse.data);
                setdefaultsubservice(apiResponse.data);
            } else {
                alert("Something went wrong");
            }
        });
    };

    const getaddonsservice = () => {
        const formData = new FormData();
        formData.append("service_id", chooseservice);
        formData.append("user_id", userid);
        getaddon(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setdefaultaddons(apiResponse.data);
                }
                else {
                    setdefaultaddons([]);
                }
            } else {
                alert("Something went wrong");
            }
        });
    };


    useEffect(() => {
        if (chooseservice === "1") {
            const formData = new FormData();
            // formData.append("user_id", LoggedInData.id);
            if (LoggedInData && LoggedInData.id) {
                formData.append("user_id", LoggedInData.id);
            } else {
                console.error("LoggedInData is null or does not have an id property");
            }
            getuserdetails(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === 1) {
                        const apiResponse = res.data;
                        setUserbilledhours(apiResponse.data.billed_hours);
                        setUserbilledamount(apiResponse.data.billed_amount);
                        setBedrooms(apiResponse.data.bedrooms);
                        setBathrooms(apiResponse.data.bathrooms);
                    }
                } else {
                    alert("Something went wrong");
                }
            });
        }
    }, [chooseservice]);


    function getEditTime(time) {
        if (!time) return ""; // Return empty if time is null/undefined

        const [hours, minutes] = time.split(":").map(Number);

        if (isNaN(hours) || isNaN(minutes)) return "";

        // Force "01:00" to always be PM
        if (hours === 1) return `01:${String(minutes).padStart(2, "0")} PM`;

        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

        return `${String(formattedHours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${period}`;
    }

    function getCalculateEndtime(startTime, durationMinutes) {
        if (!startTime || isNaN(durationMinutes)) return ""; // Handle invalid input

        // Ensure the input time is valid and parse hours
        let [hours, minutes] = startTime.split(":").map(Number);
        if (isNaN(hours)) return ""; // Invalid format
        minutes = minutes || 0; // Default to 0 if minutes are not provided

        // Assume time is PM by default based on your logic
        let period = "PM";

        // Add the duration in minutes to the time
        let totalMinutes = hours * 60 + minutes + parseInt(durationMinutes, 10);

        // Calculate new hours and minutes
        let newHours = Math.floor(totalMinutes / 60);
        let newMinutes = totalMinutes % 60;

        // Adjust hours for 12-hour format and period (AM/PM)
        if (newHours >= 12) {
            period = "PM";
            if (newHours > 12) newHours -= 12; // Wrap around to 12-hour format
        }
        if (newHours < 12 && newHours >= 7) {
            period = "PM";
        }

        // Format the final time
        return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")} ${period}`;
    }

    const [promocode, setPromocode] = useState('');
    const [showpromodel, setShowPromodel] = useState(false);

    // console.log("promodatapromodatapromodata", Newservicepercentage);

    const applypromocode = () => {
        const formData = new FormData();
        formData.append("promo_code", promocode);
        applyPromo(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === 1) {
                    const apiResponse = res.data;
                    setPromodata(apiResponse.data)
                    // setPromocode('');
                    setShowPromodel(true);
                } else {
                    setPromodata({
                        "id": "0",
                        "coupon_code": "",
                        "type": "",
                        "limits": "",
                        "used": "",
                        "value": "0",
                        "expiry_date": "",
                        "status": "",
                    })
                }
            } else {
                alert("Something went wrong");
            }
        });
    }


    const deletepromocode = () => {

        setPromocode('');
        setShowPromodel(false);
        setPromodata({
            "id": "0",
            "coupon_code": "",
            "type": "",
            "limits": "",
            "used": "",
            "value": "0",
            "expiry_date": "",
            "status": "",
        });

        const discount = parseInt(promodata?.value) || 0;

        // const newPrice = Math.round(expectedprice + discount);
        const Newuserbilledamount = userbilledamount + discount;

        // setExpectedprice(newPrice);
        setUserbilledamount(Newuserbilledamount);

        if (userloggedin == true) {
            setTotalpayment(oldtotalprice);
        }
    };


    console.log("totalAmounttotalAmount expectedprice", expectedprice);


    useEffect(() => {
        if (promodata !== null) {

            //get discount value from promodata 
            const discount = promodata?.value;

            const Newuserbilledamount = (!userbilledamount || userbilledamount == 0 ? expectedprice : userbilledamount) - parseInt(discount);
            setUserbilledamount(Newuserbilledamount);

            if (userloggedin == true) {
                const percentage = (parseInt(Newuserbilledamount) * Newservicepercentage) / 100;
                const totalAmountvar1 = parseInt(Newuserbilledamount) + percentage;
                setTotalpayment(totalAmountvar1);
            }
        }

    }, [promodata])

    return (
        <div>
            <div className="booking-card">
                <div className="booking-card-header-title">Booking Summary</div>
                {
                    (orderId === null || choosetime.length > 0) ? (
                        <div className="booking-card-header">
                            <div className="booking-left">
                                <div className="booking-card-title">Booking Start</div>
                                {

                                    choosetime && <div>
                                        <div className="booking-card-subtitle">{choosetime && getDate(choosedate) || ""}</div>
                                        <div className="booking-card-time">{getTime(choosetime) || ""}</div>
                                    </div>

                                }

                            </div>
                            <div className="booking-right">
                                <div className="booking-card-title">Booking End</div>
                                <div className="booking-card-subtitle">{choosetime && getDate(choosedate) || ""}</div>
                                <div className="booking-card-time">{getEndtime(choosetime) || ""}</div>
                            </div>
                        </div>
                    ) : (
                        <div className="booking-card-header">
                            <div className="booking-left">
                                <div className="booking-card-title">Booking Start</div>
                                <div className="booking-card-subtitle">{bookingdata && getEditDate(bookingdata?.appointment_starttime_test) || ""}</div>
                                <div className="booking-card-time">{getEditTime(bookingdata?.appointment_start_time) || ""}</div>

                            </div>
                            <div className="booking-right">
                                <div className="booking-card-title">Booking End</div>
                                <div className="booking-card-subtitle">{bookingdata && getEditDate(bookingdata?.appointment_starttime_test) || ""}</div>
                                <div className="booking-card-time">{getCalculateEndtime(bookingdata?.appointment_start_time, bookingdata?.booking_order_duration_time) || ""}</div>
                            </div>
                        </div>
                    )
                }




                <hr />
                <div className="booking-card-body">
                    <div className="booking-card-body-item">
                        <div className="sub-total">
                            <div className="booking-card-footer-item-subtitle">Estimated Billed Amount</div>
                            {/* {
                                chooseservice === "1" ? (
                                    !userbilledhours || userbilledhours === 0 ? (
                                        <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + expectedprice}</div>
                                    ) : (
                                        <div className="booking-card-footer-item-price">{"$" + userbilledamount + " to $" + userbilledamount}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + expectedprice}</div>
                                )
                            } */}


                            {

                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && isQuantityChanged === false) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + Math.round(expectedprice)}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">{"$" + userbilledamount}</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">{"$" + expectedprice}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{"$" + Totalpayment + " to $" + Math.round(expectedprice)}</div>
                                )
                            }

                        </div>
                        <div className="sub-total discount">
                            <div className="booking-card-footer-item-subtitle">Estimated Billed Duration</div>
                            {/* {
                                chooseservice === "1" ? (
                                    !userbilledhours || userbilledhours === 0 ? (
                                        <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                    ) : (
                                        <div className="booking-card-footer-item-price">{convertToHHMM(userbilledhours) + " to " + convertToHHMM(userbilledhours)}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                )
                            } */}

                            {
                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && isQuantityChanged === false) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">{convertToHHMM(userbilledhours)}</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration)}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">{convertToHHMM(totalDuration) + " to " + convertToHHMM(expectedduration)}</div>
                                )
                            }

                        </div>
                    </div>
                </div>
                <hr />
                <div className="booking-card-body">
                    <div className="booking-card-body-item">
                        <div className="booking-card-body-item-title">Apply Promocode-:</div>
                        <Form.Control type="email" value={promocode} onChange={(e) => setPromocode(e.target.value)} placeholder="Enter email" className="booking-custom-input" />
                        {
                            showpromodel ? (
                                // <button className="wizard-btn btn btn-primary d-flex float-right" disabled={promocode.length === 0} onClick={deletepromocode}>Remove Promocode</button>
                                <a
                                    href="#"
                                    // className="btn btn-danger d-flex align-items-center float-end text-white text-decoration-none"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deletepromocode();
                                    }}
                                    role="button"
                                    style={{ cursor: promocode.length === 0 ? "not-allowed" : "pointer", opacity: promocode.length === 0 ? 0.6 : 1 }}
                                >
                                    Remove Promocode
                                </a>
                            ) : (
                                // <button className="wizard-btn btn btn-primary d-flex float-right" disabled={promocode.length === 0} onClick={applypromocode}>Apply</button>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        if (promocode.length === 0) return; // Prevent action if disabled
                                        e.preventDefault();
                                        applypromocode();
                                    }}
                                    role="button"
                                    style={{
                                        cursor: promocode.length === 0 ? "not-allowed" : "pointer",
                                        opacity: promocode.length === 0 ? 0.6 : 1,
                                        pointerEvents: promocode.length === 0 ? "none" : "auto",
                                    }}
                                >
                                    Apply
                                </a>
                            )
                        }

                    </div>
                </div>
                <hr />
                <div className="booking-card-body">
                    <div className="booking-card-body-item">
                        <div className="booking-card-body-item-title">Cart Items-:</div>
                        <div className="booking-card-service-name">{servicename || ""}</div>
                        {
                            choosesubservice && choosesubservice.map((item) => {
                                return (
                                    <div className="multi-service">
                                        <div className="booking-card-service-name">{item.units_title}</div>
                                        <div className="booking-card-service-name">{item.quantity}</div>
                                    </div>
                                )
                            }
                            )
                        }
                        {
                            chooseaddon && chooseaddon.map((item) => {
                                {
                                    if (item.quantity != 0) {
                                        return (
                                            <div className="multi-service">
                                                <div className="booking-card-service-name">{item.addon_service_name}</div>
                                                <div className="booking-card-service-name">{item.quantity}</div>
                                            </div>

                                        )
                                    }
                                }

                            }
                            )

                        }
                    </div>
                </div>
                <hr />
                <div className="booking-card-footer">
                    <div className="booking-card-footer-item">
                        <div className="booking-card-footer-item-title">Your Price Summary</div>
                        <div className="sub-total">
                            <div className="booking-card-footer-item-subtitle">Sub Total</div>
                            {/* {
                                chooseservice === "1" ? (
                                    !userbilledamount || userbilledamount === 0 ? (
                                        <div className="booking-card-footer-item-price">${expectedprice || "0"}</div>
                                    ) : (
                                        <div className="booking-card-footer-item-price">${userbilledamount || "0"}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">${expectedprice || "0"}</div>
                                )
                            } */}


                            {
                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && isQuantityChanged === false) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">${userbilledamount || "0"}</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                )
                            }


                        </div>
                        <div className="sub-total discount">
                            <div className="booking-card-footer-item-subtitle">Discount</div>
                            <div className="booking-card-footer-item-price">${promodata?.value || 0}</div>
                        </div>
                        <div className="sub-total total-payment">
                            <div className="booking-card-footer-item-subtitle">Total</div>
                            {
                                chooseservice === "1" ? (
                                    ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity)) && isQuantityChanged === false) ? (
                                        !userbilledhours || userbilledhours == 0 ? (
                                            <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                        ) : (
                                            <div className="booking-card-footer-item-price">${userbilledamount || "0"}</div>
                                        )
                                    ) : (
                                        <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                    )
                                ) : (
                                    <div className="booking-card-footer-item-price">${Math.round(expectedprice) || "0"}</div>
                                )
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function convertToHHMM(value) {
    const start_time = value;
    const hours = Math.floor(start_time / 60);
    const minutes = start_time % 60;

    // Format hours and minutes as two-digit numbers
    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();

    // Combine hours and minutes in the "00:00" format
    const formattedTime = formattedHours + ':' + formattedMinutes;
    return formattedTime;
}

export default Totalpayment;