import React, { useEffect, useContext, useState } from "react";
import '../../assets/css/Booking.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Map from "./components/Map/Map";
import { BsCheckCircleFill } from "react-icons/bs";
import { BookingContext } from "./BookingContext";
import { getbookingservices } from "../../Api/frontbooingApi";
import { getsubservicebyservice } from "../../Api/frontbooingApi";
import { getaddon } from "../../Api/frontbooingApi";
import { TbListCheck } from "react-icons/tb";
import { LoginContext } from '../../LoginContext';
import { getstafflistbyzone } from "../../Api/frontbooingApi";
import { getuser, getsearchpricestatus, getreport, getbookingdataNew, getuserdetails } from "../../Api/frontbooingApi";
import { NotificationContainer, NotificationManager } from "react-notifications";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';
import { getpricingdata } from "../../Api/frontbooingApi";
import { STARTING_PRICE_HOUSE_CLEANING, STARTING_TIME_HOUSE_CLEANING, BASE_PRICE, HOURLY_RATE, MOVE_IN_OUT } from "../../Api/config";


const Step1 = props => {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword, signUprequest, setSignUprequest, technicianList, setTechnicianList, expectedprice, setExpectedprice, expectedduration, setExpectedduration, availableTimestatus, setAvailableTimestatus, resetChoosetechnician, zipcode, setZipcode, bookingdata, setBookingData, Newservicepercentage, setNewServicepercentage, oldtotalprice, setOldtotalPrice, promodata, setPromodata] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);

    const [serviceList, setServiceList] = React.useState([]);
    const [serviceactive, setServiceactive] = React.useState(0);
    const [userid, setUserid] = useState('');
    const [userloggedin, setUserloggedin] = useState(false);
    const [servicepercentage, setServicepercentage] = useState(0);


    const [bedrooms, setBedrooms] = useState(1);
    const [bathrooms, setBathrooms] = useState(1);

    const [serviceloader, setServiceLoader] = useState(false);

    const [hourlyRate, setHourlyRate] = useState(0);
    const [BasePrice, setBasePrice] = useState(0);

    const [defaultsubservice, setdefaultsubservice] = useState([]);

    const [userbilledamount, setUserbilledamount] = useState(0);

    useEffect(() => {
        getServiceList(usertype);
    }, [usertype, userid]);

    useEffect(() => {
        if (LoggedInData !== null) {
            setUserid(LoggedInData.id);
            setUserloggedin(true);
            if (LoggedInData.bedrooms != null) {
                setBedrooms(LoggedInData.bedrooms);
                setUsertype(1);
            } else {
                setUsertype(0);
            }
            if (LoggedInData.bathrooms != null) {
                setBathrooms(LoggedInData.bathrooms);
            }
        } else {
            setUserloggedin(false);
        }
    }, [LoggedInData]);


    console.log("choosesubservicechoosesubservice", choosesubservice);


    useEffect(() => {
        if (chooseservice === 0) {
            setChoosesubservice([]);
            setChooseaddon([]);
            setdefaultsubservice([]);
        }
    }, []);

    const addStyle = () => {
        document.querySelector('.display-view-item').style.display = "block";
    }

    const newUser = () => {
        document.querySelector('.select-box').classList.add('active');
        document.querySelector('.select-box2').classList.remove('active');
        document.querySelector('.display-view-item').style.display = "block";
        setUsertype(0);
    }

    const existingUser = () => {
        document.querySelector('.select-box').classList.remove('active');
        document.querySelector('.select-box2').classList.add('active');
        setUsertype(1);
    }

    const getServiceList = (usertype) => {
        if (userid !== '') {
            setServiceLoader(true);
            getbookingservices(usertype).then((res) => {
                if (res.code === 200) {
                    const apiResponse = res.data;
                    setServiceList(apiResponse.data);
                    setServiceLoader(false);
                } else {
                    alert("Something went wrong");
                }
            });
        }

    };

    const getsubservice = (service) => {
        // setLoading(true);
        const formData = new FormData();
        formData.append("service_id", service);
        formData.append("user_id", userid);
        getsubservicebyservice(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const apiResponse = res.data;
                setdefaultsubservice(apiResponse.data);
                console.log("apiResponse in subservice", apiResponse);
                // setSubService(apiResponse.data);


                // if (choosesubservice.length === 0) {
                const updatedData = apiResponse.data.map(item => {
                    if (item.units_title === "Bedrooms" && bedrooms > 0) {
                        return { ...item, quantity: bedrooms, disable: true };
                    } else if (item.units_title === "Bathrooms" && bathrooms > 0) {
                        return { ...item, quantity: bathrooms, disable: true };
                    }
                    else {
                        return { ...item, disable: false };;
                    }
                });
                setChoosesubservice(updatedData);
                // }
            } else {
                alert("Something went wrong");
            }
        });
    };

    const getaddonsservice = (service) => {
        const formData = new FormData();
        formData.append("service_id", service);
        formData.append("user_id", userid);
        getaddon(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    // if (chooseaddon.length === 0) {
                    setChooseaddon(apiResponse.data);
                    // }
                }
                else {
                    setChooseaddon([]);
                }
            } else {
                alert("Something went wrong");
            }
        });
    };

    function changeCounter2(value, id) {
        if (value === "increment") {
            const updatedArray = choosesubservice.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) + 1,
                    };
                }

                return item;
            });
            setChoosesubservice(updatedArray);
        } else if (value === "decrement") {
            const updatedArray = choosesubservice.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) - 1,
                    };
                }

                return item;
            });
            setChoosesubservice(updatedArray);
        }
    }

    function changeAddon(value, id) {
        if (value === "increment") {
            const updatedArray = chooseaddon.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) + 1,
                    };
                }

                return item;
            });

            setChooseaddon(updatedArray);
        } else if (value === "decrement") {
            const updatedArray = chooseaddon.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: parseInt(item.quantity) - 1,
                    };
                }

                return item;
            });
            setChooseaddon(updatedArray);
        }
    }

    const setserviceselection = (e) => {
        const value = e?.target ? e.target.value : e;

        const updatedArray = serviceList.map((item) => {
            if (item.id == parseInt(value)) {
                return {
                    ...item,
                    selected: 1,
                };
            } else {
                return {
                    ...item,
                    selected: 0,
                };
            }
        });
        setServiceList(updatedArray);


        getsubservice(value);
        getaddonsservice(value);
        setChooseservice(value);
        setServiceactive(1);
    }

    const [pricingdata, setPricingdata] = useState([]);

    function filterAndExtractKeys(pricingdata, serviceId, bedrooms, bathrooms) {

        // Define the prefix based on serviceId
        let prefix = "";
        if (serviceId === 1) {
            prefix = "h"; // Service 1: keys starting with "h"
        } else if (serviceId === 6) {
            prefix = "d"; // Service 6: keys starting with "d"
        } else if (serviceId === 7) {
            prefix = "g"; // Service 7: keys starting with "g"
        } else if (serviceId === 8) {
            prefix = "m"; // Service 8: keys starting with "m"
        }

        // If no valid serviceId, return an empty array
        if (!prefix) {
            return [];
        }

        return pricingdata
            .filter(item =>
                item.bed === String(bedrooms) &&
                item.bath === String(bathrooms) &&
                Object.keys(item).some(key => key.startsWith(prefix))
            )
            .map(item => {
                // Extract only the keys that match the prefix and their values
                const filteredKeys = {};
                for (const key in item) {
                    if (key.startsWith(prefix)) {
                        filteredKeys[key] = item[key];
                    }
                }
                return filteredKeys;
            });
    }

    function extractSpecificKey(filteredData, key) {
        // Return the first matching value of the key, or undefined if the key does not exist
        for (const item of filteredData) {
            if (item[key]) {
                return item[key];
            }
        }
        return undefined; // Return undefined if the key is not found
    }

    useEffect(() => {
        if (chooseservice === "1") {
            const formData = new FormData();
            // formData.append("user_id", LoggedInData.id);
            if (LoggedInData && LoggedInData.id) {
                formData.append("user_id", LoggedInData.id);
            } else {
                console.error("LoggedInData is null or does not have an id property");
            }
            getuserdetails(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === 1) {
                        const apiResponse = res.data;
                        setUserbilledamount(apiResponse.data.billed_amount);
                    }
                } else {
                    alert("Something went wrong");
                }
            });
        }
    }, [chooseservice]);

    useEffect(() => {
        calculatePrice();
    }, [choosesubservice, chooseaddon, chooseservice, promodata]);


    const calculatePrice = () => {
        var totalAmountvar = 0;
        var totalDurationvar = 0;

        var totalAmountvaraddon = 0;
        var totalDurationvaraddon = 0;
        const totaldiscount = parseInt(promodata?.value);


        choosesubservice.map((item) => {
            const basePrice = parseInt(item.base_price);
            const baseCount = 1;
            const baseDuration = parseInt(item.base_duration);
            const extraCount = item.quantity - baseCount;
            const extraPrice = parseInt(item.base_price_extra_unit);
            const extraDuration = parseInt(item.extra_duration_per_unit_in_minute);
            const baseAmount = basePrice * baseCount;
            const extraAmount = extraPrice * extraCount;
            if (userloggedin === true && (parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity))) {
                totalAmountvar = parseInt(userbilledamount);
            } else {
                totalAmountvar = totalAmountvar + baseAmount + extraAmount;
            }
            totalAmountvar = totalAmountvar + baseAmount + extraAmount;
            totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;
        });
        chooseaddon.map((item) => {
            if (item.quantity == 0) {
            }
            else {
                const basePrice = parseInt(item.base_price);
                const baseCount = 1;
                const baseDuration = parseInt(item.base_duration);;
                const extraCount = item.quantity - baseCount;
                const extraPrice = parseInt(item.price_for_per_extra_unit);
                const extraDuration = parseInt(item.extra_minute_per_unit);
                const baseAmount = basePrice * baseCount;
                const extraAmount = extraPrice * extraCount;
                totalAmountvar = totalAmountvar + baseAmount + extraAmount;
                totalDurationvar = totalDurationvar + baseDuration + extraCount * extraDuration;

                totalAmountvaraddon = totalAmountvaraddon + baseAmount + extraAmount;
                totalDurationvaraddon = totalDurationvaraddon + baseDuration + extraCount * extraDuration;
            }

        });


        // if ((parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity))) {
        //     setExpectedprice(parseInt(totalAmountvar));
        // }

        // setTotalpayment(totalAmountvar);
        // setTotalDuration(parseInt(totalDurationvar));

        // const servicepercentagevar = (totalAmountvar * parseInt(servicepercentage)) / 100;
        // const totalAmountvarwithservice = totalAmountvar + servicepercentagevar;


        // setExpectedprice(totalAmountvarwithservice);

        // const extrarange = (totalDurationvar * parseInt(servicepercentage)) / 100;
        // const totalDurationvarwithextra = totalDurationvar + extrarange;
        // setExpectedduration(parseInt(totalDurationvarwithextra));



        const bedrooms = choosesubservice[0]?.quantity;
        const bathrooms = choosesubservice[1]?.quantity;

        const quantities = parseInt(choosesubservice[0]?.quantity) + parseInt(choosesubservice[1]?.quantity);

        if (userloggedin === true && (parseInt(choosesubservice[0]?.quantity) === parseInt(defaultsubservice[0]?.quantity)) && (parseInt(choosesubservice[1]?.quantity) === parseInt(defaultsubservice[1]?.quantity))) {
            setExpectedprice(parseInt(totalAmountvar));
            setTotalpayment(parseInt(totalAmountvar));
            setOldtotalPrice(parseInt(totalAmountvar));
            setTotalDuration(parseInt(totalDurationvar));
            setExpectedduration(parseInt(totalDurationvar));
        } else {
            switch (chooseservice) {
                case '1':
                    const filteredData = filterAndExtractKeys(pricingdata, 1, parseInt(bedrooms), parseInt(bathrooms));
                    const hBwHValue = extractSpecificKey(filteredData, "h_bw_h");

                    const totalAmountvarNew = parseInt(BasePrice) + (parseFloat(hBwHValue) * parseInt(hourlyRate));
                    if (quantities == 2) {

                        let totalAmount = parseInt(totalAmountvaraddon) + parseInt(STARTING_PRICE_HOUSE_CLEANING);
                        totalAmount = totalAmount - totaldiscount;
                        const time = parseInt(STARTING_TIME_HOUSE_CLEANING) + parseInt(totalDurationvaraddon);

                        const percentage = (parseInt(totalAmount) * servicepercentage) / 100;
                        const totalAmountvar1 = parseInt(totalAmount) + percentage;

                        const extrarange = (time * servicepercentage) / 100;
                        const totalDurationvarwithextra = time + extrarange;

                        setTotalpayment(parseInt(totalAmountvar1));
                        setOldtotalPrice(parseInt(totalAmountvar1));
                        setTotalDuration(parseInt(totalDurationvarwithextra));

                        setExpectedduration(parseInt(time));
                        setExpectedprice(parseInt(totalAmount));
                    } else {

                        let totalAmount = parseInt(totalAmountvaraddon) + parseInt(totalAmountvarNew);
                        totalAmount = totalAmount - totaldiscount;
                        console.log("totalAmounttotalAmount totalAmount",totalAmount);
                        const time = parseInt(hBwHValue * 60) + parseInt(totalDurationvaraddon);

                        const percentage = (parseInt(totalAmount) * servicepercentage) / 100;
                        let totalAmountvar1 = parseInt(totalAmount) + percentage;

                        const extrarange = (time * servicepercentage) / 100;
                        const totalDurationvarwithextra = time + extrarange;

                        setTotalpayment(parseInt(totalAmountvar1));
                        console.log("totalAmounttotalAmount totalAmountvar1",totalAmountvar1);
                        setOldtotalPrice(parseInt(totalAmountvar1));
                        setTotalDuration(parseInt(totalDurationvarwithextra));


                        setExpectedduration(time);
                        setExpectedprice(totalAmount);
                    }
                    break;
                case '6':
                    const filteredDeluxData = filterAndExtractKeys(pricingdata, 6, parseInt(bedrooms), parseInt(bathrooms));
                    const DeluxehBwHValue = extractSpecificKey(filteredDeluxData, "d_bw_h");
                    const totalAmountvar2 = parseInt(BasePrice) + (parseFloat(DeluxehBwHValue) * parseInt(hourlyRate));

                    let totalAmountNew = parseInt(totalAmountvaraddon) + parseInt(totalAmountvar2);
                    totalAmountNew = totalAmountNew - totaldiscount;
                    const timeNew = parseFloat(DeluxehBwHValue * 60) + parseInt(totalDurationvaraddon);

                    const percentageDeluxe = (parseInt(totalAmountNew) * servicepercentage) / 100;
                    // const totalAmountvarDeluxe1 = parseInt(totalAmountNew) + percentageDeluxe;

                    const extrarangeDeluxe = (timeNew * servicepercentage) / 100;
                    // const totalDurationvarwithextraDeluxe = timeNew + extrarangeDeluxe;

                    setTotalpayment(parseInt(percentageDeluxe));
                    setOldtotalPrice(parseInt(percentageDeluxe));
                    setTotalDuration(parseInt(extrarangeDeluxe));


                    setExpectedduration(parseInt(timeNew));
                    setExpectedprice(totalAmountNew);
                    break;
                case '7':
                    const filteredGeneralData = filterAndExtractKeys(pricingdata, 7, parseInt(bedrooms), parseInt(bathrooms));
                    const GeneralhBwHValue = extractSpecificKey(filteredGeneralData, "g_bw_h");
                    const totalAmountvar3 = parseInt(BasePrice) + (parseFloat(GeneralhBwHValue) * parseInt(hourlyRate));


                    let totalAmountGeneral = parseInt(totalAmountvaraddon) + parseInt(totalAmountvar3);
                    totalAmountGeneral = totalAmountGeneral - totaldiscount;
                    const timeNewGeneral = parseFloat(GeneralhBwHValue * 60) + parseInt(totalDurationvaraddon);

                    const percentageGenral = (parseInt(totalAmountGeneral) * servicepercentage) / 100;
                    // const totalAmountvarDeluxe1 = parseInt(totalAmountNew) + percentageDeluxe;

                    const extrarangeGeneral = (timeNewGeneral * servicepercentage) / 100;
                    // const totalDurationvarwithextraDeluxe = timeNew + extrarangeDeluxe;

                    setTotalpayment(parseInt(percentageGenral));
                    setOldtotalPrice(parseInt(percentageGenral));
                    setTotalDuration(parseInt(extrarangeGeneral));


                    setExpectedduration(parseInt(timeNewGeneral));
                    setExpectedprice(totalAmountGeneral);
                    break;
                case '8':
                    const filteredMoveData = filterAndExtractKeys(pricingdata, 8, parseInt(bedrooms), parseInt(bathrooms));
                    const MovehBwHValue = extractSpecificKey(filteredMoveData, "m_bw_h");
                    const totalAmountvar4 = parseInt(BasePrice) + (parseFloat(MovehBwHValue) * parseInt(hourlyRate));

                    let totalAmountMove = parseInt(totalAmountvar4) + parseInt(totalAmountvaraddon);
                    totalAmountMove = totalAmountMove - totaldiscount;
                    const timeNewMove = parseFloat(MovehBwHValue * 60) + parseInt(totalDurationvaraddon);

                    const percentageMove = (parseInt(totalAmountMove) * servicepercentage) / 100;
                    // const totalAmountvarDeluxe1 = parseInt(totalAmountNew) + percentageDeluxe;

                    const extrarangeMove = (timeNewMove * servicepercentage) / 100;
                    // const totalDurationvarwithextraDeluxe = timeNew + extrarangeDeluxe;

                    setTotalpayment(parseInt(percentageMove));
                    setOldtotalPrice(parseInt(percentageMove));
                    setTotalDuration(parseInt(extrarangeMove));


                    setExpectedduration(parseInt(timeNewMove));
                    setExpectedprice(totalAmountMove);
                    break;
                default:
                    totalAmountvar = totalAmountvar - totaldiscount;
                    setTotalpayment(totalAmountvar);
                    setOldtotalPrice(totalAmountvar);
                    setTotalDuration(parseInt(totalDurationvar));

                    const servicepercentagevar = (totalAmountvar * parseInt(servicepercentage)) / 100;
                    const totalAmountvarwithservice = totalAmountvar + servicepercentagevar;


                    setExpectedprice(totalAmountvarwithservice);

                    const extrarange = (totalDurationvar * parseInt(servicepercentage)) / 100;
                    const totalDurationvarwithextra = totalDurationvar + extrarange;
                    setExpectedduration(parseInt(totalDurationvarwithextra));
                    break;
            }
        }



    }

    useEffect(() => {
    }, [serviceList]);


    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        getuser(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == "1") {
                    const apiResponse = res.data;
                    login(apiResponse.data);
                    setZipcode(apiResponse.data.zip);
                } else {
                    NotificationManager.error(res.data.message);
                }
            } else {
                alert("Something went wrong");
            }
        });
    };
    const [error, setError] = useState(false);


    useEffect(() => {
        //if user login then zipcode come from localstorage
        if (LoggedInData != null) {
            setZipcode(LoggedInData.zip);
            // getstaffbyzipcode(LoggedInData.zip);
            getstafflistbyzone(LoggedInData.zip).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === "1") {
                        const apiResponse = res.data;
                        setTechnicianList(apiResponse.data);
                        setError(false);
                    } else {
                        setTechnicianList([]);
                        setError(true);
                    }
                } else {
                    alert("Something went wrong");
                }
            });
        }
    }, [LoggedInData]);

    useEffect(() => {
        //if user login then zipcode come from localstorage
        if (LoggedInData != null) {
            setZipcode(LoggedInData.zip);
            // getstaffbyzipcode(LoggedInData.zip);
            getstafflistbyzone(LoggedInData.zip).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === "1") {
                        const apiResponse = res.data;
                        setTechnicianList(apiResponse.data);
                        setError(false);
                    } else {
                        setTechnicianList([]);
                        setError(true);
                    }
                } else {
                    alert("Something went wrong");
                }
            });
        }

        getpricingdata()
            .then((res) => {
                if (res.code === 200) {
                    const ApiResponse = res.data;
                    setPricingdata(ApiResponse.data);
                } else {
                    alert("Something went wrong");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getstaffbyzipcode = (zip) => {

        if (zipcode.length === 0) {
            alert("Please enter zipcode");
            return false;
        }

        getstafflistbyzone(zip).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setTechnicianList(apiResponse.data);
                    setError(false);
                } else {
                    setTechnicianList([]);
                    setError(true);
                }
            } else {
                alert("Something went wrong");
            }
        });

    }

    const getstaffbyzipcodenew = () => {

        if (zipcode.length === 0) {
            alert("Please enter zipcode");
            return false;
        }

        getstafflistbyzone(zipcode).then((res) => {
            if (res.code === 200) {
                if (res.data.status === "1") {
                    const apiResponse = res.data;
                    setTechnicianList(apiResponse.data);
                    setError(false);
                    getServiceList(0);
                } else {
                    setTechnicianList([]);
                    setError(true);
                }
            } else {
                alert("Something went wrong");
            }
        });

    }


    const [searchpricestatus, setsearchpricestatus] = useState(false);
    const [bookedpercentage, setbookedpercentage] = useState(0);


    useEffect(() => {
        getsearchpricestatus().then((res) => {
            if (res.code === 200) {
                if (res.data.status === 1) {
                    const apiResponse = res.data;
                    if (apiResponse.data.status === "true") {
                        setsearchpricestatus(true);
                        const formData = new FormData();
                        formData.append("start_date", moment(new Date()).format('YYYY-MM-DD'));
                        formData.append("end_date", moment(new Date()).add(1, 'days').format('YYYY-MM-DD'));
                        getreport(formData).then((res) => {
                            if (res.code === 200) {
                                const ApiResponse = res.data;
                                getprecentagebooked(ApiResponse.data);
                            } else {
                                console.log("Something went wrong");
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                    } else {
                        setsearchpricestatus(false);
                        setbookedpercentage(0);
                    }
                }
                else {
                    console.log("Data didn`t not found!");
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }, [choosesubservice])

    const getprecentagebooked = (filteredData) => {
        const totalSlots = 14;
        const totalSlotsFilled = filteredData.reduce((acc, person) => acc + person.total_avalibility, 0);
        const totalPercentage = Math.floor((totalSlotsFilled / (filteredData.length * totalSlots)) * 100);
        setbookedpercentage(totalPercentage);
        // return totalPercentage.toFixed(2);
    }


    const setServicepercentageneww = (serviceservice_percentage) => {
        if (bookedpercentage > 0) {
            const extraAmount = (bookedpercentage / 100) * serviceservice_percentage;
            const finalPercentage = parseInt(serviceservice_percentage) + parseInt(extraAmount);
            setServicepercentage(finalPercentage);
            setNewServicepercentage(finalPercentage);
        } else {
            setServicepercentage(serviceservice_percentage);
            setNewServicepercentage(serviceservice_percentage);
        }
    }

    //get data from 
    const [service_id, setService_id] = useState('0');

    useEffect(() => {
        if (props.order_Id !== null) {
            const formData = new FormData();
            formData.append("order_id", props.order_Id);
            getbookingdataNew(formData).then((res) => {
                if (res.code === 200) {
                    if (res.data.status === "1") {
                        const apiResponse = res.data;
                        setBookingData(apiResponse.data);
                        setService_id(apiResponse.data.service_id);

                        const updatedSubservices = apiResponse.data.subservices?.map((item) => ({
                            ...item,
                            quantity: parseInt(item.quantity, 10) || 0, // Fallback to 0 if quantity is invalid
                        })) || [];
                        setChoosesubservice(updatedSubservices);

                        // Handle chooseaddon
                        const updatedAddons = apiResponse.data.addons?.map((item) => ({
                            ...item,
                            quantity: parseInt(item.addons_service_qty, 10) || 0, // Fallback to 0 if quantity is invalid
                        })) || [];
                        setChooseaddon(updatedAddons);

                        // setChoosesubservice(apiResponse.data.subservices.map((item, index) => ({ ...item, quantity: parseInt(item.quantity) })));
                        // setChooseaddon(apiResponse.data.map((item, index) => ({ ...item, quantity: item.addons_service_qty })));
                    } else {
                        console.log("Data didn`t not found!");
                    }
                } else {
                    console.log("Data didn`t not found!");
                }
            });
        }
    }, [props.order_Id])

    useEffect(() => {
        if (service_id != "0") {
            setserviceselection(service_id)
        }
    }, [service_id])


    useEffect(() => {
        // resetChoosetechnician();
    }, [props.currentStep])

    if (props.currentStep !== 1) {
        return null;
    }





    return (
        <>
            <div className="booking-form-box">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            {LoggedInData == null ? (
                                <div className="booking-warrper-mb-40">
                                    <div className="col-md-12">
                                        <div className="step-count">
                                            <div className="step-count-inner">
                                                <div className="Step-icon">
                                                    <span><TbListCheck /></span>
                                                </div>
                                                <div className="step-count-inner-title">
                                                    Choose User
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row booking-warrper-40">
                                        <div className="col-xl-6 col-lg-12 col-sm-12">
                                            <div className="select-box active" onClick={newUser}>
                                                <div className="select-box-inner">
                                                    <div className="select-box-content">
                                                        <div className="select-box-inner-title">
                                                            New User
                                                        </div>
                                                        <div className="select-box-inner-content">
                                                            <p>Create Your Account If You Dont Have Existing One</p>
                                                        </div>
                                                    </div>
                                                    <div className="select-box-icon">
                                                        <BsCheckCircleFill />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-sm-12">
                                            <div className="select-type-btn" onClick={addStyle}>
                                                <div className="select-box select-box2" onClick={existingUser}>
                                                    <div className="select-box-inner">
                                                        <div className="select-box-content">
                                                            <div className="select-box-inner-title">
                                                                Existing User
                                                            </div>
                                                            <div className="select-box-inner-content">
                                                                <p>Login With Your User Name & Password</p>
                                                            </div>
                                                        </div>
                                                        <div className="select-box-icon">
                                                            <BsCheckCircleFill />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)}
                            <div className="display-view-item" >
                                <div className="use-zip-code">
                                    <div className="row" style={LoggedInData != null || usertype === 1 ? { display: "none" } : { display: "block" }}>
                                        <div className="col-md-12">
                                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                <Form.Label>Please Enter Zip Code</Form.Label>
                                            </Form.Group>
                                        </div>
                                        <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-9">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" value={zipcode} onChange={(e) => { setZipcode(e.target.value); setUserid(1); }} placeholder="Enter zipcode" className="booking-custom-input" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3">
                                            <div className="search-btn">
                                                <button type="button" className="btn search-btn-custom" onClick={getstaffbyzipcodenew} >Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={usertype !== 0 && LoggedInData === null ? { display: "block" } : { display: "none" }}>
                                        <div className="col-md-12">
                                            <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                <Form.Label>Please Enter Login Details</Form.Label>
                                            </Form.Group>
                                        </div>
                                        <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-9">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" value={email} onChange={(e) => { setemail(e.target.value); }} placeholder="Enter email" className="booking-custom-input" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-9">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="password" value={password} onChange={(e) => { setpassword(e.target.value); }} placeholder="Enter password" className="booking-custom-input" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-3">
                                            <div className="search-btn">
                                                <button type="button" className="btn search-btn-custom" onClick={handleSubmit} >login</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="alert alert-danger" role="alert" style={{
                                    display: error ? "block" : "none"
                                }}>
                                    Sorry, we are not available in your area yet.
                                </div>
                                {/* <div className="Map-view booking-warrper-mb-40">
                                    <Map />
                                </div> */}
                            </div>

                            <div className="hide-content-view booking-warrper-40" style={{
                                display: technicianList.length > 0 ? "block" : "none"
                            }} >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="step-count">
                                            <div className="step-count-inner">
                                                <div className="Step-icon">
                                                    <span><TbListCheck /></span>
                                                </div>
                                                <div className="step-count-inner-title">
                                                    Choose A Services
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="Service-details-box booking-warrper-40">
                                        <div className="row">
                                            {
                                                serviceloader ? <Spinner
                                                    animation="grow"
                                                    size="lx"
                                                    role="status"
                                                    style={{ color: "#75b317" }}
                                                /> : (
                                                    <Form>
                                                        {['radio'].map((type) => (
                                                            <div key={`inline-${type}`} className="mb-3">
                                                                <div className="Service-details-box-inner" >
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="selexted-service-check" >
                                                                            {serviceList && serviceList.map((data) => (
                                                                                <Form.Check
                                                                                    inline
                                                                                    // label={data.title}
                                                                                    label={data.title === "Training - Meeting" ? "Training" : data.title}
                                                                                    name="group1"
                                                                                    type={type}
                                                                                    // checked={data.selected === 1 ? true : false}
                                                                                    checked={bookingdata && bookingdata.service_id.includes(data.id)}
                                                                                    id={`inline-${type}-${data.id}`}
                                                                                    value={data.id}
                                                                                    onClick={(e) => {
                                                                                        setBookingData((prev) => ({
                                                                                            ...prev,
                                                                                            service_id: [data.id], // Only one service should be selected
                                                                                        }));
                                                                                        setserviceselection(e);
                                                                                        setServicename(data.title);
                                                                                        // setServicepercentage(data.service_percentage);
                                                                                        setServicepercentageneww(data.service_percentage);
                                                                                        setHourlyRate(data.hourly_rate);
                                                                                        setBasePrice(data.base_price);
                                                                                    }}
                                                                                    className="Service-details-box-inner-title"
                                                                                    disabled={!!props.order_Id}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Form>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hide-content-view booking-warrper-40 serviceoption" {...serviceactive == 0 ? { style: { display: "none" } } : { style: { display: "block" } }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="step-count">
                                            <div className="step-count-inner">
                                                <div className="Step-icon">
                                                    <span><TbListCheck /></span>
                                                </div>
                                                <div className="step-count-inner-title">
                                                    Service Options
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="extra-service booking-warrper-40">
                                    <h6 className="extra-service-title">Sub Services</h6>
                                    <div className="row">
                                        {choosesubservice && choosesubservice.map((item) => {
                                            return (
                                                <div className="col-md-12">
                                                    <div className="Service-details-box-inner extra-service">
                                                        <div className="left-side">
                                                            <div className="d-flex align-items-center">
                                                                <div className="selexted-service-check selexted-service-check-7">
                                                                    {/* <Form>
                                                                        <Form.Check aria-label="option 7" />
                                                                    </Form> */}
                                                                </div>
                                                                <h3 className="Service-details-box-inner-title">{item.units_title}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="right-side">
                                                            <div className="d-flex align-items-center">
                                                                <div className="extra-service-icon">
                                                                    {/* Button for decrement subservices */}
                                                                    {/* {
                                                                        item.disable === true ?
                                                                            <Button
                                                                                className="add-item-btn"
                                                                                disabled={true}
                                                                                onClick={(e) => {
                                                                                    changeCounter2("decrement", item.id);
                                                                                }
                                                                                }
                                                                            >
                                                                                -
                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                className="add-item-btn"
                                                                                disabled={item.quantity == 1 ? true : false}
                                                                                onClick={(e) => {
                                                                                    changeCounter2("decrement", item.id);
                                                                                }
                                                                                }
                                                                            >
                                                                                -
                                                                            </Button>
                                                                    } */}
                                                                    {/* Button for decrement subservices */}
                                                                    <Button
                                                                        className="add-item-btn"
                                                                        disabled={item.quantity == 1 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeCounter2("decrement", item.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        -
                                                                    </Button>

                                                                </div>
                                                                <div className="extra-service-value">
                                                                    {item.quantity}
                                                                </div>
                                                                <div className="extra-service-icon">
                                                                    {/* Button for increment subservices */}
                                                                    {/* {
                                                                        item.disable === true ?
                                                                            <Button
                                                                                className="add-item-btn"
                                                                                disabled={true}
                                                                                onClick={(e) => {
                                                                                    changeCounter2("increment", item.id);
                                                                                }
                                                                                }
                                                                            >
                                                                                +
                                                                            </Button> :
                                                                            <Button
                                                                                className="add-item-btn"
                                                                                disabled={item.quantity == 6 ? true : false}
                                                                                onClick={(e) => {
                                                                                    changeCounter2("increment", item.id);
                                                                                }
                                                                                }
                                                                            >
                                                                                +
                                                                            </Button>
                                                                    } */}
                                                                    {/* Button for increment subservices */}
                                                                    <Button
                                                                        className="add-item-btn"
                                                                        disabled={item.quantity == 6 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeCounter2("increment", item.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="extra-service booking-warrper-40">
                                    <h6 className="extra-service-title">Addons</h6>
                                    <div className="row">
                                        {chooseaddon.map((value, index) => {
                                            return (
                                                <div className="col-md-12">
                                                    <div className="Service-details-box-inner extra-service mb-3">
                                                        <div className="left-side">
                                                            <div className="d-flex align-items-center">
                                                                <h3 className="Service-details-box-inner-title">
                                                                    {value.addon_service_name}
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="right-side">
                                                            <div className="d-flex align-items-center">
                                                                <div className="extra-service-icon">
                                                                    <Button
                                                                        className="add-item-btn"
                                                                        disabled={value.quantity == 0 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeAddon("decrement", value.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        -
                                                                    </Button>
                                                                </div>
                                                                <div className="extra-service-value">
                                                                    {value.quantity}
                                                                </div>
                                                                <div className="extra-service-icon">
                                                                    <Button
                                                                        className="add-item-btn"
                                                                        disabled={value.quantity == 6 ? true : false}
                                                                        onClick={(e) => {
                                                                            changeAddon("increment", value.id);
                                                                        }
                                                                        }
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </>
    );
};

export default Step1;
