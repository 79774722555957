import React, { useState, useContext } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./Header2.css";
import Logo from "../../assets/Images/logo/Logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Popuplogo from "../../assets/Images/logo/popuplogo.png";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import { LoginContext } from "../../LoginContext";
import { getuser } from "../../Api/frontbooingApi";
import { faPowerOff, faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavDropdown from "react-bootstrap/NavDropdown";
import Icon from "../../assets/Images/dsicon.webp";
import { BsList } from "react-icons/bs";
import { BiGridAlt, BiLogOutCircle } from "react-icons/bi";
import { SignUp, forgetpasswordapi } from "../../Api/frontbooingApi";
import Spinner from 'react-bootstrap/Spinner';


const Header2 = () => {
  const { LoggedInData, login, logout } = useContext(LoginContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [validated, setValidated] = useState(false);
  const [forgetvalidated, setForgetValidated] = useState(false);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phone, setphone] = useState("");
  const [zip, setzip] = useState("");
  const [city, setcity] = useState("");
  const [address, setaddress] = useState("");
  const [state, setstate] = useState("");
  const [emailexist, setemailexist] = useState(false);
  const [signUprequest, setSignUprequest] = useState(0);

  const [apicall, setapicall] = useState(false);


  const UserSignUp = () => {

    if (email == "" || password == "" || firstname == "" || lastname == "" || phone == "" || zip == "" || city == "" || address == "" || state == "") {
      setSignUprequest(signUprequest + 1);
      return false;
    }

    if (password != confirmpassword) {
      NotificationManager.error('Password and confirm password should be same', 'Close after 3000ms', 3000);
      return false;
    }


    setapicall(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("confirmpassword", confirmpassword);
    formData.append("first_name", firstname);
    formData.append("last_name", lastname);
    formData.append("phone", phone);
    formData.append("zipcode", zip);
    formData.append("address", address);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("user_type", "0");
    SignUp(formData).then((res) => {
      if (res.code === 200) {
        if (res.data.status == '1') {
          handleShow();
          setapicall(false);
          handleClose2();
          setfirstname("");
          setlastname("");
          setemail("");
          setpassword("");
          setconfirmpassword("");
          setphone("");
          setzip("");
          setaddress("");
          setstate("");
          setcity("");
          setSignUprequest(0); // Reset sign up request counter
          setemailexist(false)
          //how to from reset 

        }
        else {
          //get error message 
          if (res.data.message) {
            setapicall(false);
            setemailexist(true);
          }
        }

      } else {
        alert("Something went wrong");
      }
    });

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      getuser(formData).then((res) => {
        if (res.code === 200) {
          if (res.data.status == "1") {
            const apiResponse = res.data;
            login(apiResponse.data);
            handleClose();
            window.location.href = "/userdashboard";
          } else {
            const message = res.data.message;
            console.log("message", message);


            if (typeof message === 'string') {
              const combinedMessage = message
                .split('</p>')
                .filter(msg => msg.trim() !== '')
                .map(msg => msg.replace(/<\/?p>/g, '').trim())
                .join(' ');

              console.log("combinedMessage", combinedMessage);
              NotificationManager.error(combinedMessage);
            } else {
              console.error("Expected a string of messages but got:", message);
            }
            // NotificationManager.error(combinedMessage);
          }
        } else {
          alert("Something went wrong");
        }
      });
    }



    setValidated(true);
  };

  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const toggle = () => {
    document.querySelector(".menu-icon").classList.toggle("active");
    setCollapse(!collapse);
  };

  const [collapse, setCollapse] = useState(false);
  const addClass = () => {
    if (addClass) {
      document.querySelector(".stop").classList.add("new-class-name");
    } else {
      document
        .querySelector(".sidebar-container")
        .classList.remove("new-class-name");
      document.querySelector(".stop").classList.remove("new-class-name");
    }
  };

  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const states = [
    { name: "Alabama", cities: ["Birmingham", "Montgomery", "Mobile"], stateCode: "AL" },
    { name: "Alaska", cities: ["Anchorage", "Fairbanks", "Juneau"], stateCode: "AK" },
    { name: "Arizona", cities: ["Phoenix", "Tucson", "Mesa"], stateCode: "AZ" },
    { name: "Arkansas", cities: ["Little Rock", "Fort Smith", "Fayetteville"], stateCode: "AR" },
    { name: "California", cities: ["Los Angeles", "San Diego", "San Jose"], stateCode: "CA" },
    { name: "Colorado", cities: ["Denver", "Colorado Springs", "Aurora"], stateCode: "CO" },
    { name: "Connecticut", cities: ["Bridgeport", "New Haven", "Hartford"], stateCode: "CT" },
    { name: "Delaware", cities: ["Wilmington", "Dover", "Newark"], stateCode: "DE" },
    { name: "Florida", cities: ["Jacksonville", "Miami", "Tampa"], stateCode: "FL" },
    { name: "Georgia", cities: ["Atlanta", "Augusta", "Columbus"], stateCode: "GA" },
    { name: "Hawaii", cities: ["Honolulu", "Pearl City", "Hilo"], stateCode: "HI" },
    { name: "Idaho", cities: ["Boise", "Nampa", "Meridian"], stateCode: "ID" },
    { name: "Illinois", cities: ["Chicago", "Aurora", "Rockford"], stateCode: "IL" },
    { name: "Indiana", cities: ["Indianapolis", "Fort Wayne", "Evansville"], stateCode: "IN" },
    { name: "Iowa", cities: ["Des Moines", "Cedar Rapids", "Davenport"], stateCode: "IA" },
    { name: "Kansas", cities: ["Wichita", "Overland Park", "Kansas City"], stateCode: "KS" },
    { name: "Kentucky", cities: ["Louisville", "Lexington", "Bowling Green"], stateCode: "KY" },
    { name: "Louisiana", cities: ["New Orleans", "Baton Rouge", "Shreveport"], stateCode: "LA" },
    { name: "Maine", cities: ["Portland", "Lewiston", "Bangor"], stateCode: "ME" },
    { name: "Maryland", cities: ["Baltimore", "Frederick", "Rockville"], stateCode: "MD" },
    { name: "Massachusetts", cities: ["Boston", "Worcester", "Springfield"], stateCode: "MA" },
    { name: "Michigan", cities: ["Detroit", "Grand Rapids", "Warren"], stateCode: "MI" },
    { name: "Minnesota", cities: ["Minneapolis", "Saint Paul", "Rochester"], stateCode: "MN" },
    { name: "Mississippi", cities: ["Jackson", "Gulfport", "Southaven"], stateCode: "MS" },
    { name: "Missouri", cities: ["Kansas City", "Saint Louis", "Springfield"], stateCode: "MO" },
    { name: "Montana", cities: ["Billings", "Missoula", "Great Falls"], stateCode: "MT" },
    { name: "Nebraska", cities: ["Omaha", "Lincoln", "Bellevue"], stateCode: "NE" },
    { name: "Nevada", cities: ["Las Vegas", "Henderson", "Reno"], stateCode: "NV" },
    { name: "New Hampshire", cities: ["Manchester", "Nashua", "Concord"], stateCode: "NH" },
    { name: "New Jersey", cities: ["Newark", "Jersey City", "Paterson"], stateCode: "NJ" },
    { name: "New Mexico", cities: ["Albuquerque", "Las Cruces", "Rio Rancho"], stateCode: "NM" },
    { name: "New York", cities: ["New York", "Buffalo", "Rochester"], stateCode: "NY" },
    { name: "North Carolina", cities: ["Charlotte", "Raleigh", "Greensboro"], stateCode: "NC" },
    { name: "North Dakota", cities: ["Fargo", "Bismarck", "Grand Forks"], stateCode: "ND" },
    { name: "Ohio", cities: ["Columbus", "Cleveland", "Cincinnati"], stateCode: "OH" },
    { name: "Oklahoma", cities: ["Oklahoma City", "Tulsa", "Norman"], stateCode: "OK" },
    { name: "Oregon", cities: ["Portland", "Salem", "Eugene"], stateCode: "OR" },
  ];

  const [forgetemail, setForgetemail] = useState("");
  const [showtext, setshowtext] = useState(false);
  const [alertmessage, setAlertmessage] = useState(false);


  const forgetpassword = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {

      setapicall(true);
      const formdata = new FormData();
      formdata.append("email", forgetemail);

      forgetpasswordapi(formdata).then((res) => {
        if (res.code === 200) {
          if (res.data.status == '1') {
            setshowtext(true);
            setAlertmessage(false);
            // handleClose1();
          }
          else {
            setAlertmessage(true);
            setshowtext(false);
            // handleClose1();
          }

        } else {
          alert("Something went wrong");
        }
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        setapicall(false);
      })
    }



    setForgetValidated(true);
  };

  return (
    <>
      <nav className="navbar cstm-navbar">
        <div className="container"  >
          <h1 className="navbar-logo">
            <a href="https://greenfrogcleaning.com/" className="navbar-logo" rel="noopener noreferrer">
              <img src={Logo} alt="Google" className="logo-image" />
            </a>
          </h1>
          <div onClick={toggle}>
            <div className="menu-icon" onClick={handleClick}>
              <BsList className={active} />
              {/* <i className={active ? "fas fa-times" : "fas fa-bars"}></i> */}
            </div>
          </div>
          <ul
            className={
              active
                ? "nav-menu custom-header-nav-menu active"
                : "nav-menu custom-header-nav-menu"
            }
          >
            {/* <li className={splitLocation[1] === "" ? "active" : ""}>
              <Link to="/" className="nav-links">
                Home
              </Link>
            </li> */}
            <li className={splitLocation[1] === "about-us" ? "active" : ""}>
              <a href="https://greenfrogcleaning.com/about-us/" className="nav-links" rel="noopener noreferrer">
                About Us
              </a>
            </li>

            <li className="nav-item dropdown">
              <Dropdown className="header-dropdwn">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Services
                </Dropdown.Toggle>

                <Dropdown.Menu className="home-drop-show">
                  <Dropdown.Item
                    href="https://greenfrogcleaning.com/house-cleaning-services/"
                    // target="_blank"
                    rel="noopener noreferrer"
                    className="hover-new"
                  >
                    House Cleaning Services
                  </Dropdown.Item>

                  <Dropdown.Item
                    href="https://greenfrogcleaning.com/window-cleaning/"
                    // target="_blank"
                    rel="noopener noreferrer"
                    className="hover-new"
                  >
                    Window Cleaning Services
                  </Dropdown.Item>

                  <Dropdown.Item
                    href="https://greenfrogcleaning.com/how-to-clean-and-disinfect-your-homes-most-touched-areas/"
                    // target="_blank"
                    rel="noopener noreferrer"
                    className="hover-new"
                  >
                    House Cleaning Tips
                  </Dropdown.Item>

                  <Dropdown.Item
                    href="https://greenfrogcleaning.com/our-cleaning-products/"
                    // target="_blank"
                    rel="noopener noreferrer"
                    className="hover-new"
                  >
                    Our Products
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className={splitLocation[1] === "reviews" ? "active" : ""}>
              <a href="https://greenfrogcleaning.com/review" className="nav-links" rel="noopener noreferrer">
                Review
              </a>
            </li>
            <li className={splitLocation[1] === "contact-us" ? "active" : ""}>
              <a href="https://greenfrogcleaning.com/contact-us" className="nav-links" rel="noopener noreferrer">
                Contact
              </a>
            </li>

            <div className="header-login-btn">
              {LoggedInData == null ? (
                <Button className="login-btn-view" onClick={handleShow}>
                  Sign in
                </Button>
              ) : (
                <NavDropdown
                  className="nav-menu dash-drop"
                  title={`${LoggedInData.first_name} ${LoggedInData.last_name}`}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item href="/userdashboard" className="nav-menu">
                    {" "}
                    <BiGridAlt />{" "}
                    <span className="ps-1">Dashboard</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="nav-menu" onClick={logout}>
                    <BiLogOutCircle />
                    <span className="ps-2">Logout</span>
                  </NavDropdown.Item>
                </NavDropdown>
              )}
            </div>
          </ul>

        </div>
      </nav>

      <Modal show={show} onHide={handleClose} className="custom-model" centered>
        <Modal.Header closeButton>
          <div className="modal-nav">
            <div className="modal-logo">
              <img src={Popuplogo} alt="logo" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body2">
          <div className="modal-tltle">Sign in to your Account</div>
          <div className="form-section">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className="custom-lable">Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder="Enter email"
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your email address
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className="custom-lable">Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="Password"
                  className="custom-input"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your password
                </Form.Control.Feedback>
              </Form.Group>
              <Button className="Login-btn-view" type="submit">
                Submit
              </Button>
            </Form>
          </div>
          <div className="col-md-12">
            <span className="seperator">can’t access your account?</span>
          </div>
          <div className="form_btn_group row gx-2">
            <div className="col-7">
              <button onClick={() => {
                handleClose();
                handleShow1();
              }} class="btn btn-dark form-btn-mini form-btn btn-block" type="button" id="login_lost_btn"><span class="btn_text">Forgot password</span></button>
            </div>
            <div className="col-5">
              <button onClick={
                () => {
                  handleShow2();
                  handleClose();
                }
              } class="btn btn-green form-btn-mini form-btn btn-block" type="button" id="login_register_btn"><span class="btn_text">Sign up</span></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* forgot password modal start here */}

      <Modal show={show1} onHide={handleClose1} className="custom-model" centered>
        <Modal.Header closeButton>
          <div className="modal-nav">
            <div className="modal-logo">
              <img src={Popuplogo} alt="logo" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body2">
          <div className="modal-tltle">Forgot your Password</div>
          <div className="form-section">
            <span className="forgot-text" style={{
              color: "green",
            }}>{showtext && "Please check your email."}</span>
            <Form noValidate validated={forgetvalidated} onSubmit={forgetpassword}>
              <Form.Group className="mb-4" controlId="validationCustom01">
                <Form.Label className="custom-lable">Email address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  className="custom-input"
                  value={forgetemail}
                  onChange={(e) => setForgetemail(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  The email address you entered is invalid
                </Form.Control.Feedback>
                {
                  alertmessage && <span className="danger" style={{
                    color: "red",
                  }}>Your email address in invalid.</span>
                }
              </Form.Group>
              <Button className="Login-btn-view" type="submit">
                {
                  apicall ? <Spinner animation="border" /> : "Submit"
                }
              </Button>
            </Form>
          </div>
          <div className="col-md-12">
            <span className="seperator">can’t access your account?</span>
          </div>
          <div className="form_btn_group row gx-2">
            <div className="col-6">
              <button class="btn btn-dark form-btn-mini form-btn btn-block" type="button" id="login_lost_btn" onClick={
                () => {
                  handleClose1();
                  handleShow();
                }
              } ><span class="btn_text">Sign in</span></button>
            </div>
            <div className="col-6">
              <button class="btn btn-green form-btn-mini form-btn btn-block" type="button" id="login_register_btn" onClick={
                () => {
                  handleClose1();
                  handleShow2();
                }
              }><span class="btn_text">Sign up</span></button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* sign up modal start here  */}
      <Modal show={show2} onHide={handleClose2} className="custom-model" centered>
        <Modal.Header closeButton>
          <div className="modal-nav">
            <div className="modal-logo">
              <img src={Popuplogo} alt="logo" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body2">
          <div className="modal-tltle">Create a new account</div>
          <div className="form-section">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row">

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label className="custom-lable">First name</Form.Label>
                    <Form.Control
                      required
                      type="name"
                      placeholder="first name"
                      onChange={(e) => setfirstname(e.target.value)}
                      className="custom-input"
                      value={firstname}
                    />
                    {

                      firstname == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a first name.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom02">
                    <Form.Label className="custom-lable">Last name</Form.Label>
                    <Form.Control
                      required
                      type="name"
                      placeholder="last name"
                      className="custom-input"
                      value={lastname}
                      onChange={(e) => setlastname(e.target.value)}
                    />
                    {

                      lastname == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a last name.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom03">
                    <Form.Label className="custom-lable">Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      value={email}
                      placeholder="email"
                      onChange={(e) => {
                        setemail(e.target.value);
                        setemailexist(false);
                      }}
                      className="custom-input"
                    />
                    {

                      email == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a valid email.</div>

                        : null
                    }
                    {

                      emailexist == true ?
                        <div class="form-error">The email address already exists.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom04">
                    <Form.Label className="custom-lable">Preferred Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      placeholder=""
                      className="custom-input"
                    />
                    {

                      password == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a password.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom05">
                    <Form.Label className="custom-lable">Confirm Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      value={confirmpassword}
                      onChange={(e) => setconfirmpassword(e.target.value)}
                      placeholder="Password"
                      className="custom-input"
                    />
                    {
                      confirmpassword != password && confirmpassword != "" ?

                        <div class="form-error">Password and confirm password not match.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom06">
                    <Form.Label className="custom-lable">Phone</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      placeholder="Phone"
                      className="custom-input"
                    />
                    {

                      phone == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a  phone number.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom07">
                    <Form.Label className="custom-lable">Zip Code</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={zip}
                      onChange={(e) => setzip(e.target.value)}
                      placeholder="Zip code"
                      className="custom-input"
                    />
                    {

                      phone == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a phone number.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom08">
                    <Form.Label className="custom-lable">Street Address</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                      placeholder="Street Address"
                      className="custom-input"
                    />
                    {

                      address == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a street address.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom09">
                    <Form.Label className="custom-lable">State</Form.Label>
                    <Form.Select aria-label="Default select example" className="custom-select-box" onChange={(e) => setstate(e.target.value)}>
                      <option>Select State</option>

                      {states.map((state) => (
                        <option value={state.stateCode}>{state.name}</option>
                      ))}

                    </Form.Select>
                    {

                      state == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a state.</div>

                        : null
                    }
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom010">
                    <Form.Label className="custom-lable">City</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={city}
                      placeholder="City"
                      onChange={(e) => setcity(e.target.value)}
                      className="custom-input"
                    />
                    {

                      city == "" && signUprequest > 0 ?
                        <div class="form-error">Please provide a city.</div>

                        : null
                    }
                  </Form.Group>
                </div>

              </div>
              <Button className="Login-btn-view" onClick={UserSignUp}>
                {apicall ? <Spinner animation="border" /> : "Create Account"}
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <NotificationContainer />
    </>
  );
};

export default Header2;
